import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ProseDark from "../../components/prose-dark"
import axios from 'axios'
import ProseLight from "../../components/prose-light"
import AlertForm from "../../components/widgets/alertForm"

class InformedPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      agreement: false,
      captcha: undefined,
      recaptchaInstance: undefined,
      formError: [],
      formSuccess: '',
    };
  }

  componentDidMount() {
    if (this.state.recaptchaInstance) {
      this.state.recaptchaInstance.reset();
    }
  }

  submit = (event) => {
    event.preventDefault()
    this.setState({ formSuccess: '', formError: [] }, this.handleFormEntry)

  }

  handleFormEntry = () => {

    // console.log(this.props.title)

    if (this.state.name === '' ||
      this.state.email === '' ||
      this.state.message === ''
    ) {
      this.setState({ formError: this.state.formError.concat(["Form not filled out completely."]) })
    } else {
      let formData = new FormData()
      formData.set('name', this.state.name)
      formData.set('email', this.state.email)
      formData.set('message', this.state.message)
      // formData.set('website', this.props.title)
      formData.set('website', 'Touchstone')
      formData.set('response', this.state.captcha)

      axios.post('https://backend.touchstonedistrictservices.com/mailer', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
          }
        }).then(function (response) {
          // console.log('Response');
          // console.log(response.data);
          if (response.data.status === "success") {
            this.clearForm()
            this.setState({ formSuccess: "Your message has been sent." })
          } else {
            this.setState({ formError: this.state.formError.concat(["Something went wrong, check your entry and try again."]) })
          }

        }.bind(this)).catch(function (error) {
          console.log(error.response)
        })

      if (this.state.recaptchaInstance) {
        this.state.recaptchaInstance.reset();
      }

    }

  }

  clearForm = () => {
    this.setState({ name: '', email: '', message: '' })
  }

  changeName = (event) => {
    this.setState({ name: event.target.value })
  }
  changeAgreement = (event) => {
    this.setState({ agreement: event.target.checked })
  }
  changeEmail = (event) => {
    this.setState({ email: event.target.value })
  }
  changeMessage = (event) => {
    this.setState({ message: event.target.value })
  }
  captchaVerify = (response) => {
    this.setState({ captcha: response })
  }
  onLoadRecaptcha = () => {
    if (this.state.recaptchaInstance) {
      this.state.recaptchaInstance.reset();
    }
  }

  render() {

    return (
      <Layout>
        <SEO title="Get Informed" />
        <div className="bg-midnight w-full min-h-screen px-8 py-36">
          <div className="m-auto w-full container">
            <ProseDark>
              <div className="grid md:grid-cols-2 gap-12">
                <div>
                  <div className="text-base mb-4 -mt-4">
                    <Link className="inline-block no-underline hover:text-yellow-400 hover:underline text-white font-normal" to="/">Home</Link>
                    <span className="opacity-50"> <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg> Get Informed</span>
                  </div>
                  <h1 className="text-6xl md:text-8xl !mb-0 leading-none">Stay in the Know<span className="text-yellow-400">.</span>
                  </h1>
                  <p className="text-lg pt-8"><a className="no-underline" href="tel:+18325585714">832 558 5714</a><br /><a className="no-underline" href="mailto:info@touchstonedistrictservices.com">info@touchstonedistrictservices.com</a></p>
                  <p className="text-base">8118 Fry Rd., Ste. 703,<br />Cypress, TX 77433</p>
                </div>
                <div>

                  <p>Sign up using the form below to receive important updates.</p>
                  <AlertForm />
                  <p className="text-base">*By providing your cell phone and checking the box, you consent to receive calls and texts, including autodialed and automated calls and texts with notifications from Touchstone District Services. These may include marketing updates and alerts. At any time, you may discontinue by replying STOP and request assistance by replying HELP. Message frequency may vary. Message and data rates may apply. Terms and Conditions and Privacy Policy can be <Link to="/legal">found here</Link>.</p>

                </div>
              </div>
            </ProseDark>
          </div>
        </div>
      </Layout>
    )
  }
}

export default InformedPage
